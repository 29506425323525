<template>
    <section class="tables">
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h2 class="card-title">{{ $t('names.payedOrders.name') }}</h2>
                        <p class="card-description">{{ $t('names.payedOrders.description') }}</p>
                        <b-table striped hover :items="computedItems" :fields="fields" @row-clicked="openDetails" class="clickable"></b-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <b-pagination
                v-model="pageNr"
                :total-rows="allPages"
                per-page="1"
                first-number
                last-number
                @change="handlePageChange"
                align="right">
            </b-pagination>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'
    import moment from 'moment'

    export default {
        name: 'OrderRequestPayedList',
        computed: {
            computedItems() {
                return this.items.map((item)=>{
                    let tmp=item;
                    switch (item.paymentStatus) {
                        case 'WAITING':
                            tmp._cellVariants={ paymentStatus: 'primary'}
                            break;
                        case 'PENDING':
                            tmp._cellVariants={ paymentStatus: 'primary'}
                            break;
                        case 'WAITING_FOR_CONFIRMATION':
                            tmp._cellVariants={ paymentStatus: 'primary'}
                            break;
                        case 'COMPLETED':
                            tmp._cellVariants={ paymentStatus: 'success'}
                            break;
                        case 'CANCELED':
                            tmp._cellVariants={ paymentStatus: 'danger'}
                            break;
                        case 'FINALIZED':
                            tmp._cellVariants={ paymentStatus: 'dark'}
                            break;
                        default:
                            tmp._cellVariants={ paymentStatus: 'danger'}
                            break;
                    }
                    return tmp;
                })
            }
        },
        data() {
            return {
                pageNr: 1,
                allPages: "",
                items: [],
                fields: [
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true,
                        thStyle: 'width: 75px;'
                    },
                    {
                        key: 'createdDate',
                        label: this.$t('forms.created'),
                        sortable: true,
                        formatter: value => {
                            return moment(value).format('DD.MM.YYYY, HH:mm');
                        }
                    },
                    {
                        key: 'name',
                        label: this.$t('forms.name'),
                        sortable: true,
                        formatter: value => {
                            return this.trimEllip(value, 20)
                        }
                    },
                    {
                        key: 'surname',
                        label: this.$t('forms.surname'),
                        sortable: true,
                        formatter: value => {
                            return this.trimEllip(value, 20)
                        }
                    },
                    {
                        key: 'phone',
                        label: this.$t('forms.contactPhone'),
                        sortable: false
                    },
                    {
                        key: 'email',
                        label: this.$t('forms.email'),
                        sortable: true,
                        formatter: value => {
                            return this.trimEllip(value, 30)
                        }
                    },
                    {
                        key: 'basket.totalGrossPrice',
                        label: this.$t('forms.orderValue'),
                        sortable: true,
                        class: 'text-right',
                        formatter: value => {
                            if (value == null || value === "" || value === undefined) {
                                return '-'
                            }
                            return value.toFixed(2)
                        }
                    },
                    {
                        key: 'paymentStatus',
                        label: this.$t('forms.paymentStatus'),
                        sortable: true,
                        formatter: value => {
                            switch (value) {
                            case 'WAITING':
                                return this.$t('forms.paymentStatusStarted')
                            case 'PENDING':
                                return this.$t('forms.paymentStatusPending')
                            case 'WAITING_FOR_CONFIRMATION':
                                return this.$t('forms.paymentStatusWaitingConfirmation')
                            case 'COMPLETED':
                              return this.$t('forms.paymentStatusFinished')
                            case 'CANCELED':
                              return this.$t('forms.paymentStatusCanceled')
                            case 'FINALIZED':
                              return this.$t('forms.paymentStatusRefund')
                            default:
                              return this.$t('forms.paymentStatusUnknown')
                            }
                        },
                        class: 'text-right'
                    }
                ]
            }
        },
        mounted() {
            this.refreshPage()
        },
        methods: {
            openDetails(item) {
                this.$router.push('/order-request/' + item.id)
            },
            trimEllip(s, length) {
                return s.length > length ? s.substring(0, length) + "..." : s;
            },
            handlePageChange(value) {
                this.pageNr = value;
                this.refreshPage()

            },
            refreshPage() {
                if (this.pageNr == null || this.pageNr === "") {
                    this.pageNr = 1
                }
                axios.get(Const.API_HOST + '/admin/orders/paid/' + this.pageNr)
                    .then(function (response) {
                        return response
                    }).catch(function (error) {
                  // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.items = body.data.orders
                    this.pageNr = body.data.pageNr
                    this.allPages = body.data.allPages
                })
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

</style>
