import Vue from 'vue'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import store from './store'
import axios from 'axios'
import jwtService from './mixins/JwtStorageService'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Viewer from 'v-viewer'
import routes from './routes';

import 'jquery/src/jquery.js'

import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'viewerjs/dist/viewer.css'

const VueUploadComponent = require('vue-upload-component')

Vue.use(VueI18n)
Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(Viewer)
Vue.component('file-upload', VueUploadComponent)

export const Const = Object.freeze({
    LANG: process.env.VUE_APP_LANG,
    API_HOST: process.env.VUE_APP_API_HOST_URL,
    WEB_HOST: process.env.VUE_APP_WEB_PAGE_HOST_URL,
    MODE_NEW: 'new',
    MODE_EDIT: 'edit',
})

Vue.config.productionTip = false

const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
});
export default router;

axios.interceptors.request.use(
    config => {
        const token = jwtService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.headers['Content-Type'] = 'application/json';

        return config;
    },
    error => {
        // eslint-disable-next-line no-console
        console.error(error)
        Promise.reject(error)
    }
);

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {

        if (isRefreshing) {
            return new Promise(function(resolve, reject) {
                failedQueue.push({resolve, reject})
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const credential = {
            grant_type: "refresh_token",
            refresh_token: jwtService.getRefreshToken()
        };
        return new Promise(function (resolve, reject) {
            axios.post(Const.API_HOST + '/oauth/access_token', credential)
                .then(({data}) => {
                    jwtService.setAccessToken(data.access_token);
                    jwtService.setRefreshToken(data.refresh_token)
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
                    originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
                    processQueue(null, data.access_token);
                    resolve(axios(originalRequest));
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                })
                .then(() => { isRefreshing = false })
        })
    }

    return Promise.reject(error);
});

const messages = {
    pl: {
        fillFieldsBelow: "Wypełnij poniższe pola",
        editEntry: "Edycja wpisu:",
        createNew: "Nowy",
        createNewTitle: "Nowy wpis",
        signin: "Logowanie",
        signinDescription: "Aby się zalogować, podaj swój adres email i hasło.",
        invalidCredential: "Nieprawidłowa nazwa użytkownika lub hasło.",
        logout: "Wyloguj",
        shop: "Sklep",
        roles: {
            admin: "Administrator",
            orders: "Zamówienia - odczyt",
            cms: "Administrator CMS",
        },
        names: {
            about: {
                name: "O firmie",
                description: "Element cześci 'O firmie' na stronie głównej"
            },
            carousel: {name: "Karuzela", description: "Elementy karuzeli na stronie głównej"},
            departments: {
                name: "Oddziały",
                description: "Dane oddziałów, które zostaną wyświetlone na stronie"
            },
            documents: {name: "Dokumenty", description: "Dokumenty i regulaminy"},
            faqCategories: {
                name: "Kategorie FAQ",
                description: "Kategoria FAQ, która zostanie wyświetlona w nowej kolumnie na stronie WWW"
            },
            faqs: {name: "Wpisy FAQ", description: "Definicja wpisu FAQ"},
            pageFragments: {
                name: "Fragmenty stron",
                description: "Statyczne fragmenty stron. Modyfikuj te wpisy tylko wtedy, gdy wiesz co robisz!"
            },
            infos: {
                name: "Aktualności",
                description: "Informacje i artykuły, które zostaną wyświetlone w zakładce 'Aktualności'"
            },
            pages: {
                name: "Strony",
                description: "Definisja strony, która będzie widoczna pod zdefiniowanym adresem URL"
            },
            orderRequests: {
                name: "Zapytania o wycenę",
                description: "Zapytania o wyceny skompletowanych koszyków. Zawiera wycenione produkty oraz takie, dla których cena nie jest określone w sklepie"
            },
            payedOrders: {
                name: "Zamówienia",
                description: "Zamówienia z wybraną opcją przedpłaty za pomocą bramki płatniczej PayU"
            },
            productCategories: {
                name: "Kategorie produktów",
                description: "Kategorie, do których musi zostać przypisany każdy produkt. Modyfikuj te wartości tylko wtedy, gdy wiesz co robisz!"
            },
            products: {name: "Produkty", description: "Definicje produktów dostępnych w sklepie"},
            prices: {name: "Ceny", description: "Ceny produktów z określonym serwisem"},
            services: {
                name: "Typy serwisów",
                description: "Typy serwisów (częstotliwości) dostępne do wybrania w definicji produktu"
            }
        },
        forms: {
            password: "Hasło",
            name: "Nazwa",
            key: "Klucz",
            title: "Tytuł",
            description: "Opis",
            content: "Zawartość",
            category: "Kategoria",
            keywords: "Słowa kluczowe",
            order: "Kolejność",
            file: "Plik",
            files: "Pliki",
            image: "Obraz",
            images: "Obrazy",
            mainImage: "Główny obraz",
            thumbnail: "Miniatura",
            image360: "Zdjęcie 360°",
            imageSize: "Rozmiar",
            imageAlt: "Tekst alternatywny",
            created: "Data utworzenia",
            author: "Autor",
            select: "Wybierz",
            save: "Zapisz",
            cancel: "Anuluj",
            delete: "Usuń",
            close: "Zamknij",
            change: "Zamień",
            back: "Wstecz",
            add: "Dodaj",
            warning: "Uwaga",
            yes: "tak",
            no: "nie",
            postal: "Kod pocztowy",
            city: "Miasto",
            address: "Adres",
            addressExt: "Adres (druga linia)",
            openHours: "Godziny otwarcia",
            email: "Adres email",
            longitude: "Szerokość geo.",
            latitude: "Długość geo.",
            homePage: "Strona główna",
            question: "Pytanie",
            answer: "Odpowiedź",
            orderRequest: "Zamówienie",
            clientDetails: "Dane klienta",
            firstname: "Imię",
            surname: "Nazwisko",
            fiscalNumber: "NIP",
            companyName: "Nazwa firmy",
            contactPhone: "Telefon kontaktowy",
            orderDetails: "Dane zamówienia",
            orderId: "Nr zamówienia",
            deliveryAddress: "Adres dostawy",
            deliveryDate: "Wynajem od",
            endDate: "Wynajem do",
            rentalPeriod: "Okres wynajmu",
            deliveryContactPhone: "Telefon dla kierowcy",
            deliveryInfo: "Uwagi dla kierowcy",
            regulationAccepted: "Akceptacja regulaminu",
            owuAccepted: "Akceptacja OWU",
            rodoAccepted: "Zgoda RODO",
            basketPositions: "Pozycje w koszyku",
            productName: "Produkt",
            productDatasheet: "Karta produktu",
            service: "Serwis",
            quantity: "Ilość",
            periods: "Okresy",
            unitPrice: "Cena jednostkowa",
            price: "Cena",
            netPrice: "Cena netto",
            grossPrice: "Cena brutto",
            feeType: "Typ opłaty",
            feeTypeOneTime: "Jednorazowa",
            feeTypeRecurring: "Cykliczna",
            orderNetPrice: "Kwota zamówienia netto",
            orderGrossPrice: "Kwota zamówienia brutto",
            days: "dni",
            recurringFeeQty: "x co 1 tydz.",
            orderValue: "Kwota zamówienia",
            paymentStatus: "Status płatności",
            paymentStatusStarted: "Rozpoczęta",
            paymentStatusPending: "Oczekująca",
            paymentStatusWaitingConfirmation: "Czeka na potwierdzenie",
            paymentStatusFinished: "Zakończona",
            paymentStatusCanceled: "Anulowana",
            paymentStatusRefund: "Zwrot",
            paymentStatusUnknown: "Nieznany",
            buyPossibility: "Możliwość zakupu",
            productFeatures: "Najważniejsze cechy produktu",
            productFeaturesPrompt: "Wpisz cechę produktu",
            productFeatureExist: "Taka cecha już istnieje!",
            productFeaturesEmpty: "Nie została dodana żadna cecha produktu",
            productVases: "Usługi dodatkowe",
            deleteConfirmationMessage: "Czy na pewno chcesz usunąć ten wpis? Ta operacja jest nieodwracalna i wpis zostanie trwale usunięty z systemu.",
            deleteConfirmationMessageExt: "Czy na pewno chcesz usunąć ten wpis? Ta operacja jest nieodwracalna i wpis zostanie trwale usunięty z systemu. Usunięcie tego wpisu będzie miało ogromny wpływ na działanie i wygląd systemu, a wszystkie powiązane z nim wpisy również zostaną usunięte. Jeśli nie jesteś pewien tej operacji, zrezygnuj z niej.",
            saveConfirmationMessageExt: "Czy na pewno chcesz zapisać ten wpis? Ta operacja spowoduje zapisanie zmian, które mogą mieć znaczący wpływ na działanie i wygląd systemu. Jeśli nie jesteś pewien tych zmian, zrezygnuj z tej operacji.",
            imagePickerTitle: "Wybierz obraz",
            filePickerTitle: "Wybierz plik",
            writeHere: "Pisz tutaj...",
            status: "Status",
            statusOk: "ok",
            statusSending: "przesyłam",
            statusWaiting: "czeka",
            dropHere: "Przeciągnij pliki tutaj",
            or: "lub",
            selectFromDisk: "Wybierz z dysku",
            startUpload: "Ropocznij upload",
            stopUpload: "Zatrzymaj upload",
            showUrl: "Pokaż URL",
            edit: "Edycja"
        }
    }, en: {
        fillFieldsBelow: "Please fill in the fields below",
        editEntry: "Edit:",
        createNew: "New",
        createNewTitle: "New entry",
        signin: "Sign in",
        signinDescription: "To sign in, please enter your email address and password.",
        invalidCredential: "Invalid username or password.",
        logout: "Logout",
        shop: "Shop",
        roles: {
            admin: "Administrator",
            orders: "Orders - read",
            cms: "CMS Administrator",
        },
        names: {
            about: {name: "About", description: "The 'About' section on the main page"},
            carousel: {name: "Carousel", description: "Carousel elements on the main page"},
            departments: {
                name: "Departments",
                description: "Data of the departments to be displayed on the page"
            },
            documents: {name: "Documents", description: "Documents and regulations"},
            faqCategories: {
                name: "FAQ Categories",
                description: "FAQ category that will be displayed in a new column on the website"
            },
            faqs: {name: "FAQs", description: "Definition of an FAQ entry"},
            pageFragments: {
                name: "Page Fragments",
                description: "Static page fragments. Modify these entries only if you know what you are doing!"
            },
            infos: {
                name: "News",
                description: "Information and articles to be displayed in the 'News' tab"
            },
            pages: {
                name: "Pages",
                description: "Definition of a page that will be visible at a defined URL address"
            },
            orderRequests: {
                name: "Price Requests",
                description: "Requests for quotes of completed baskets. Contains priced products as well as those for which the price is not specified in the store"
            },
            payedOrders: {
                name: "Orders",
                description: "Orders with the prepayment option selected via the PayU payment gateway"
            },
            productCategories: {
                name: "Product Categories",
                description: "Categories to which each product must be assigned. Modify these values only if you know what you are doing!"
            },
            products: {name: "Products", description: "Definitions of products available in the store"},
            prices: {name: "Prices", description: "Prices of products with a specified service"},
            services: {
                name: "Services",
                description: "Types of services (frequencies) available for selection in the product definition"
            }
        },
        forms: {
            password: "Password",
            name: "Name",
            key: "Key",
            title: "Title",
            description: "Description",
            content: "Content",
            category: "Category",
            keywords: "Keywords",
            order: "Order",
            file: "File",
            files: "Files",
            image: "Image",
            images: "Images",
            mainImage: "Main image",
            thumbnail: "Thumbnail",
            image360: "Image 360°",
            imageSize: "Size",
            imageAlt: "Alt text",
            created: "Created",
            author: "Author",
            select: "Select",
            save: "Save",
            cancel: "Cancel",
            delete: "Delete",
            close: "Close",
            change: "Change",
            back: "Back",
            add: "Add",
            warning: "Warning",
            yes: "yes",
            no: "no",
            postal: "Postal",
            city: "City",
            address: "Address",
            addressExt: "Address (second line)",
            openHours: "Opening hours",
            email: "E-mail address",
            longitude: "Longitude",
            latitude: "Latitude",
            homePage: "Home page",
            question: "Question",
            answer: "Answer",
            orderRequest: "Order request",
            clientDetails: "Customer data",
            firstname: "Firstname",
            surname: "Surname",
            fiscalNumber: "Fiscal",
            companyName: "Company name",
            contactPhone: "Contact phone number",
            orderDetails: "Order details",
            orderId: "Order ID",
            deliveryAddress: "Delivery address",
            deliveryDate: "Rental from",
            endDate: "Rental to",
            rentalPeriod: "Rental period",
            deliveryContactPhone: "Phone for the driver",
            deliveryInfo: "Comment for the driver",
            regulationAccepted: "Accepted regulations",
            owuAccepted: "Accepted OWU",
            rodoAccepted: "Accepted RODO",
            basketPositions: "Basket positions",
            productName: "Product",
            productDatasheet: "Product datasheet",
            service: "Service",
            quantity: "Qty",
            periods: "Periods",
            unitPrice: "Unit price",
            price: "Price",
            netPrice: "Net price",
            grossPrice: "Gross price",
            feeType: "Fee type",
            feeTypeOneTime: "One-time fee",
            feeTypeRecurring: "Recurring",
            orderNetPrice: "Order net price",
            orderGrossPrice: "Order gross price",
            days: "days",
            recurringFeeQty: "x every 1 week",
            orderValue: "Order value",
            paymentStatus: "Payment status",
            paymentStatusStarted: "Started",
            paymentStatusPending: "Pending",
            paymentStatusWaitingConfirmation: "Confirmation waiting",
            paymentStatusFinished: "Finished",
            paymentStatusCanceled: "Canceled",
            paymentStatusRefund: "Refund",
            paymentStatusUnknown: "Unknown",
            buyPossibility: "Can buy",
            productFeatures: "Important product features",
            productFeaturesPrompt: "Enter a product feature",
            productFeatureExist: "This feature already exists!",
            productFeaturesEmpty: "No product feature has been added",
            productVases: "Additional services",
            deleteConfirmationMessage: "Are you sure you want to delete this entry? This action is irreversible and the entry will be permanently removed from the system.",
            deleteConfirmationMessageExt: "Are you sure you want to delete this entry? This action is irreversible and the entry will be permanently removed from the system. Deleting this entry will have a significant impact on the functionality and appearance of the system, and all related entries will also be deleted. If you are not sure about this operation, please cancel it.",
            saveConfirmationMessageExt: "Are you sure you want to save this entry? This action will save the changes, which may have a significant impact on the functionality and appearance of the system. If you are not sure about these changes, please cancel this operation.",
            imagePickerTitle: "Select image",
            filePickerTitle: "Select file",
            writeHere: "Write here...",
            status: "Status",
            statusOk: "ok",
            statusSending: "sending",
            statusWaiting: "waiting",
            dropHere: "Drop here",
            or: "or",
            selectFromDisk: "Select from disk",
            startUpload: "Start upload",
            stopUpload: "Stop upload",
            showUrl: "Show URL",
            edit: "Edit"
        }
    }
}

const i18n = new VueI18n({
    locale: Const.LANG, // set locale
    fallbackLocale: 'en',
    messages, // set locale messages
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
